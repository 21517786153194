import { toast } from 'react-toastify'
import moment from 'moment'

// To set or get data in local storage
export const setLocalStorage = (key: string, value: any) =>
  localStorage.setItem(key, value)

export const getLocalStorage = (key: string) => localStorage.getItem(key)

// To display error message
export const errorToast = (msg: string) => {
  toast.error(msg)
  toast.clearWaitingQueue()
}

export const successToast = (msg: string) => {
  toast.success(msg)
  toast.clearWaitingQueue()
}

export const dangerouslySetHTML = (html, style?: any) => {
  let updatedValue = html?.toString()
  if (html?.includes('<?php')) {
    updatedValue = html
      ?.replaceAll('<?php', '&lt;?php')
      ?.replaceAll('?>', '?&gt;')
      ?.replaceAll('\\n', '\n ')
  }
  return (
    <pre
      style={
        style
          ? style || {}
          : {
              fontFamily: `'Lato', sans-serif`,
              marginTop: '0.1rem',
              marginBottom: 0,
              wordBreak: 'break-all',
              fontSize: '1rem',
            }
      }
    >
      <span
        dangerouslySetInnerHTML={{
          __html: updatedValue || '-',
        }}
      />
    </pre>
  )
}
export const secondsToHms = (sec: number) => {
  sec = Number(sec)
  if (isNaN(sec)) return '00:00:00'
  const h = Math.floor(sec / 3600)
  const m = Math.floor((sec % 3600) / 60)
  const s = Math.floor((sec % 3600) % 60)
  let hDisplay: string
  if (sec > 86400) {
    hDisplay = '1 Day, ' + ' '
  } else {
    hDisplay = h > 0 ? h + ' h, ' : ''
  }
  const mDisplay = m > 0 ? m + (m === 1 ? ' min, ' : ' mins, ') : ''
  const sDisplay = s > 0 ? s + (s === 1 ? ' sec' : ' secs') : ''
  return hDisplay + mDisplay + sDisplay
}

export const convertUtcToItcFormat = (utcTime) => {
  const d = new Date()
  return moment(d.toLocaleDateString() + ' ' + utcTime + '+00:00')
    .local()
    .format('LTS')
}

export const minToHms = (duration) => {
  // TODO: If day need to be display
  // const days = duration / (60 * 60 * 24)
  // ${String(Math.floor(days)).padStart(2, '0')}

  const hours = (duration / (60 * 60)) % 24
  const minutes = (duration / 60) % 60
  const seconds = duration % 60
  return `
            ${String(Math.floor(hours)).padStart(2, '0')}:
            ${String(Math.floor(minutes)).padStart(2, '0')}:
            ${String(Math.floor(seconds)).padStart(2, '0')}`
}

export const selectViolationTime = [
  {
    value: 1,
    label: '1 times',
  },
  {
    value: 2,
    label: '2 times',
  },
  {
    value: 3,
    label: '3 times',
  },
]

export const findDifficultyLevel = (level) => {
  switch (level) {
    case 'E':
      return 'Easy'
    case 'M':
      return 'Medium'
    case 'H':
      return 'Hard'
  }
}

export const difficultyLevel = (level) => {
  switch (level) {
    case 'EASY':
      return 'Easy'
    case 'MEDIUM':
      return 'Medium'
    case 'HARD':
      return 'Hard'
  }
}

export const handleQuestionOptions = (option) => {
  switch (option) {
    case 0:
      return 'A'
    case 1:
      return 'B'
    case 2:
      return 'C'
    case 3:
      return 'D'
    case 4:
      return 'E'
    case 5:
      return 'F'
  }
}

export const formatBytes = (bytes, decimals = 2) => {
  if (!+bytes) return '0 Bytes'

  const k = 1024
  const dm = decimals < 0 ? 0 : decimals
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']

  const i = Math.floor(Math.log(bytes) / Math.log(k))

  return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`
}

export function generateUUID() {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
    const r = (Math.random() * 16) | 0,
      v = c === 'x' ? r : (r & 0x3) | 0x8
    return v.toString(16)
  })
}

export const getPercentage = (value: number, totalValue: number) => {
  return !value || value < 0 ? 0 : (value * 100) / (totalValue || 1)
}

export const dateFormat = (date) => {
  return moment(date).format('DD MMM, YYYY')
}

export const showCreatedAgo = (created_at) => {
  return `${moment(created_at).fromNow(true)} ago`
}

export const updateAgencyInLocalStorage = (updatedFields) => {
  const storedString = localStorage.getItem('persist:user')

  if (storedString) {
    const storedObject = storedString ? JSON.parse(storedString) : null

    if (storedObject.user) {
      const userObject =
        storedObject && storedObject.user ? JSON.parse(storedObject.user) : null
      const userDetailsObject =
        storedObject && storedObject.userDetails
          ? JSON.parse(storedObject.userDetails)
          : null

      if (userObject.user?.agency || userObject.user?.agency === null) {
        userObject.user.agency = {
          ...userObject.agency,
          ...updatedFields,
        }
      }
      if (userDetailsObject?.agency || userDetailsObject?.agency === null) {
        userDetailsObject.agency = {
          ...userDetailsObject.agency,
          ...updatedFields,
        }
      }

      storedObject.user = JSON.stringify(userObject)
      storedObject.userDetails = JSON.stringify(userDetailsObject)

      const updatedStoredString = JSON.stringify(storedObject)
      localStorage.setItem('persist:user', updatedStoredString)
    }
  }
}

export const RESULT_BUFFER_TIME = 5
export const ASCENDING = 'asc'
export const DESCENDING = 'desc'

export const emailRegex = /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/
export const emailWithPlusRegex = /^[\w.+]+@([\w-]+\.)+[\w-]{2,4}$/

export const facebookRegex =
  // eslint-disable-next-line no-useless-escape
  /(?:(http|https):\/\/)?(?:www.)?facebook.com\/(?:(?:\w)*#!\/)?(?:pages\/)?(?:[?\w\-]*\/)?(?:profile.php\?id=(\d.*))?([\w\-]+)?/

export const InstagramRegex =
  /(?:(?:http|https):\/\/)?(?:www.)?(?:instagram.com|instagr.am)\/([A-Za-z0-9-_]+)/

export const twitterRegex =
  // eslint-disable-next-line no-useless-escape
  /(?:(http|https):\/\/)?(?:www\.)?twitter\.com\/(?:(?:\w)*#!\/)?(?:pages\/)?(?:[\w\-]*\/)*([\w\-]*)/

export const linkedInRegex =
  /^(?:https?:\/\/)?(?:[a-z]{2,3}\.)?linkedin\.com\/(company|school)\/([A-Za-z0-9-À-ÿ.]+)\/?$/

export const websiteRegex =
  /(ftp|http|https):\/\/(\w+:{0,1}\w*@)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@!\-/]))?/

export const personNameRegex = /^[a-zA-Z]+$/
