import { useEffect, useState } from 'react'
import {
  Col,
  Container,
  Dropdown,
  Image,
  Modal,
  Nav,
  Navbar,
  Row,
} from 'react-bootstrap'
import { useSelector } from 'react-redux'
import { Link, NavLink, useNavigate } from 'react-router-dom'

import { getProfile, logout } from '@redux/auth/authActions'
import { Store } from '@redux/rootType'
import { dispatch } from '@redux/store'

import CompanyPlanImg from '@assets/images/company-plan-img.png'
import GrayDotIcon from '@assets/images/gray-dots.svg'
import Notification from '@assets/images/header-iconOne.png'
import DefaultUserImage from '@assets/images/header-iconThird.png'
import HeaderLogout from '@assets/images/header-logout.svg'
import HelpCenter from '@assets/images/help-center.svg'
import LogoImage from '@assets/images/main-logo.png'
import UserIcon from '@assets/images/profiler-user-icon.png'
import RightBackArrow from '@assets/images/right-black-arrow.svg'
import Settings from '@assets/images/setting-icon.svg'
import TajIcon from '@assets/images/taj-icon.svg'
import Transaction from '@assets/images/transaction-history.svg'
import UserImagesIcon from '@assets/images/User-images.svg'

const Header = () => {
  const navigate = useNavigate()
  const handleLogout = () => {
    dispatch(logout())
    navigate('/auth/login')
  }

  const [show2, setShow2] = useState(false)

  const handleClose2 = () => setShow2(false)
  const handleShow2 = () => setShow2(true)

  const notification = () => {
    // handle notification
  }

  const userData = useSelector((state: Store) => state.authReducer.user)

  useEffect(() => {
    Object.keys(userData).length >= 1 &&
      dispatch(getProfile(userData['user'].id))
  }, [])

  return (
    <>
      <Navbar bg="white" expand="lg">
        <Container fluid className="mx-3">
          <Navbar.Brand as={Link} to="/home" style={{ width: '6.563rem' }}>
            <img src={LogoImage} alt="logo" />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav justify-content-end">
            <Nav className="ms-auto align-items-center">
              <NavLink
                to={''}
                onClick={handleShow2}
                className="header-gradient-link"
              >
                <Image src={TajIcon} className="me-1" />
                Upgrade
              </NavLink>

              <Dropdown>
                <Dropdown.Toggle
                  as={Link}
                  id="dropdown-basic"
                  className="notify"
                  to="#"
                >
                  <Nav.Link href="#" className="px-4">
                    <img src={Notification} alt="notification Image" />
                  </Nav.Link>
                </Dropdown.Toggle>
                <div className="drop-down top-notification">
                  <Dropdown.Menu>
                    <div className="mx-auto">
                      <div className="d-flex mx-3 mt-3">
                        <p className="fw-semibold m-0">Notification</p>
                        <Link
                          to=""
                          className="ms-auto fw-semibold font-size-14"
                        >
                          Mark all read
                        </Link>
                      </div>
                    </div>
                    <hr className="custom-hr p-0 my-2 mx-0" />
                    <div className="tab-light-border p-3">
                      <div className="my-2" onClick={notification}>
                        <Row>
                          <Col lg={2} className="align-self-center">
                            <Image
                              src={UserImagesIcon}
                              className="me-3"
                              width={'50px'}
                              height={'30px'}
                            />
                          </Col>
                          <Col lg={10}>
                            <p className="mb-0 text">
                              HR <Link to="">@Robert R</Link> Requested to
                              purchase questionnaire for Dec 2022 Batch.
                            </p>
                          </Col>
                          <Col lg={{ offset: 2, span: 10 }}>
                            <p className="mt-2 mb-0 font-size-12">06:25pm</p>
                          </Col>
                        </Row>
                        <hr className="custom-hr p-0 my-2 mx-0" />
                      </div>
                      <div className="my-2" onClick={notification}>
                        <Row>
                          <Col lg={2} className="align-self-center">
                            <Image
                              src={UserImagesIcon}
                              className="me-3"
                              width={'50px'}
                              height={'30px'}
                            />
                          </Col>
                          <Col lg={10}>
                            <p className="mb-0 text">
                              HR <Link to="">@Robert R</Link> Requested to
                              purchase questionnaire for Dec 2022 Batch.
                            </p>
                          </Col>
                          <Col lg={{ offset: 2, span: 10 }}>
                            <p className="mt-2 mb-0 font-size-12">06:25pm</p>
                          </Col>
                        </Row>
                        <hr className="custom-hr p-0 my-2 mx-0" />
                      </div>
                      <div className="text-center see-all">
                        <Link
                          to="/notification"
                          className="fw-semibold font-size-14"
                        >
                          View all
                        </Link>
                      </div>
                    </div>
                  </Dropdown.Menu>
                </div>
              </Dropdown>
              <Dropdown>
                <Dropdown.Toggle as={Link} id="dropdown-basic" to="#">
                  <img src={DefaultUserImage} className="0header-profile-img" />
                </Dropdown.Toggle>
                <Dropdown.Menu className="my-profile-dropdown p-0">
                  <Dropdown.Item
                    as={Link}
                    to="/myProfile"
                    state={{ active: 'first' }}
                  >
                    <div className="d-flex align-items-center">
                      <img className="me-2" src={UserIcon} />
                      <div className="ms-2">
                        Profile
                        <br />
                        <span className="text-muted">
                          {userData?.user?.role ?? '-'}
                        </span>
                      </div>
                      <img src={RightBackArrow} className="ms-auto" />
                    </div>
                  </Dropdown.Item>
                  <hr className="m-0" style={{ borderTopColor: '#F5F5F5' }} />

                  <Dropdown.Item as={Link} to="/transaction-history">
                    <img className="me-2" src={Transaction} /> Transaction
                    History
                  </Dropdown.Item>
                  <hr className="m-0" style={{ borderTopColor: '#F5F5F5' }} />
                  <Dropdown.Item as={Link} to="/system-setting">
                    <img className="me-2" src={Settings} /> Settings
                  </Dropdown.Item>
                  <hr className="m-0" style={{ borderTopColor: '#F5F5F5' }} />

                  <Dropdown.Item as={Link} to="/help-center">
                    <img className="me-2" src={HelpCenter} /> Help Center
                  </Dropdown.Item>
                  <Dropdown.Item onClick={handleLogout}>
                    <img className="me-2" src={HeaderLogout} />
                    Logout
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>

      <Modal
        show={show2}
        onHide={handleClose2}
        centered
        className="request-empale-modal"
        aria-labelledby="contained-modal-title-vcenter"
      >
        <Modal.Body className="modal-body mx-auto p-3 m-3">
          <div className="text-center">
            <h4 className="mb-3">Unlock Companies List</h4>
            <span>1000+ Active Jobs</span>
            <Image src={GrayDotIcon} className="mx-2" />
            <span>100+ Companies</span>
            <div className="py-4 my-3">
              <Image src={CompanyPlanImg} />
            </div>
            <p className="dark-gray mb-3">
              Select a plan to access company details
            </p>
            <button className="btn px-4 btn-primary">Select Plan</button>
          </div>
        </Modal.Body>
      </Modal>
    </>
  )
}

export default Header
